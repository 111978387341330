
import { defineComponent } from 'vue'
import { useStore } from '../store'
import { useRouter } from 'vue-router'
import HeaderView from '../views/HeaderView.vue'
import SectionRow from '../components/SectionRow.vue'
import PriceView from '../components/PriceView.vue'
//import FmfmFree from "../components/FmfmFree.vue";
import CancelView from '../components/CancelView.vue'
import TrialButton from '../components/TrialButton.vue'
import YoutubeView from '../components/YoutubeView.vue'
import InstallationGuide from '../components/InstallationGuide.vue'
import QuestionView from '../components/QuestionView.vue'
import FunctionView from '../components/FunctionView.vue'

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore()
    const moveToPlan = () => {
      router.push('/plan')
    }
    const moveToSuccess = () => {
      router.push('/success')
    }
    // store.dispatch("getProducts");
    return {
      store,
      router,
      moveToPlan,
      moveToSuccess,
    }
  },
  components: {
    HeaderView,
    SectionRow,
    PriceView,
    YoutubeView,
    CancelView,
    TrialButton,
    InstallationGuide,
    QuestionView,
    FunctionView,
  },
  data() {
    return {
      notSignedInWarning: false,
    }
  },
  async mounted() {
    this.store.dispatch('getProducts')
    this.store.dispatch('addOnAuthStateChanged')

    const uid = this.$route.query.u
    if (uid) await this.store.dispatch('authWithAccessToken', { uid: uid })

    const page = this.$route.query.page
    if (page == 'success') this.moveToSuccess()
  },
  computed: {
    isPremiumUser(): boolean {
      return this.store.state.userInfo.isPremiumUser
    },
    isAuthenticated(): boolean {
      return this.store.state.userInfo.isAuthenticated
    },
    phraseThree(): string {
      if (this.store.state.userInfo.isTrialingUser) {
        return 'あなたは現在トライアル会員です'
      } else if (this.store.state.userInfo.isPremiumUser) {
        return 'あなたはVIP会員です'
      } else {
        return '今なら7日間無料！いつでも解約OK！'
      }
    },
    startButtonText() {
      if (this.store.state.userInfo.isPremiumUser) {
        return '支払い情報の確認'
      } else {
        return '無料トライアルを始める'
      }
    },
  },
  methods: {
    signin() {
      this.store.dispatch('signin', { token: undefined })
    },
    async kakin() {
      if (!this.store.state.userInfo.isAuthenticated) {
        this.notSignedInWarning = true
        console.log(this.notSignedInWarning)
        setTimeout(() => {
          this.notSignedInWarning = false
        }, 300)
        return
      }
      this.moveToPlan()
    },
    subscription() {
      if (!this.store.state.userInfo.isAuthenticated) {
        window.scroll({ top: 0, behavior: 'smooth' })
        this.store.dispatch('showWarning')
        setTimeout(() => {
          this.store.dispatch('hideWarning')
        }, 2000)
        return
      }
      if (this.isPremiumUser) {
        this.store.dispatch('callBillingPortal')
      } else {
        this.store.dispatch('checkout')
      }
    },
  },
})
